import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import {
  getCurrentBusinessAccountUser,
  getCurrentSession,
} from "src/services/AuthService";
import { TypeProduct } from "src/services/ProductService";

/**
 * Store type that will be used by views.
 */
export type TypeStore = {
  storeId: string;
  uniqueStoreName: string;
  storeName: string;
  storeWebsite: string;
  storeDescription: string;
  contactNumber: string;
  contactEmail: string;
  storeCoverImage: string;
  yelpLink: string;
  twitterLink: string;
  facebookLink: string;
  instagramLink: string;
  storeLocation: string;
  storeLocationLongitude: number;
  storeLocationLatitude: number;
};

/**
 * A type contains search stores API parameters.
 */
export type SearchStoresParameters = {
  storeCategories?: string;
  latitude?: string;
  longitude?: string;
};

/**
 * Search stores.
 *
 * @param searchStoresParameters
 * @returns an array of TypeStore instances.
 */
export const searchStores = async (
  searchStoresParameters: SearchStoresParameters
) => {
  const searchStoresApi =
    process.env.REACT_APP_BACKEND_SERVICE_API +
    "/stores?" +
    new URLSearchParams(searchStoresParameters).toString();

  const res = await fetch(searchStoresApi);
  const response = await res.json();
  const storeList: TypeStore[] = response.map((obj: any) => {
    return {
      storeId: obj["storeId"],
      storeName: obj["storeName"],
      uniqueStoreName: obj["uniqueStoreName"],
      storeDescription: obj["storeDescription"],
      storeWebsite: obj["storeWebsite"],
      contactNumber: obj["contactNumber"],
      contactEmail: obj["contactEmail"],
      storeCoverImage: obj["storeCoverImage"],
      yelpLink: obj["yelpLink"],
      facebookLink: obj["facebookLink"],
      instagramLink: obj["instagramLink"],
      storeLocation: obj["storeLocation"],
      storeLocationLatitude: obj["storeLocationLatitude"],
      storeLocationLongitude: obj["storeLocationLongitude"],
    };
  });

  return storeList;
};

/**
 * Get store information.
 *
 * @param unique_store_name
 * @returns a TypeStore instance.
 */
export const getStoreInfo = async (unique_store_name: string) => {
  const getStoreInfoApi =
    process.env.REACT_APP_BACKEND_SERVICE_API + "/stores/" + unique_store_name;
  const res = await fetch(getStoreInfoApi);
  const obj = await res.json();
  if (obj.statusCode === 404) {
    return null;
  }
  const store: TypeStore = Object.assign(obj, {
    storeId: obj["storeId"],
    storeName: obj["storeName"],
    uniqueStoreName: obj["uniqueStoreName"],
    storeDescription: obj["storeDescription"],
    storeWebsite: obj["storeWebsite"],
    contactNumber: obj["contactNumber"],
    contactEmail: obj["contactEmail"],
    storeCoverImage: obj["storeCoverImage"],
    yelpLink: obj["yelpLink"],
    facebookLink: obj["facebookLink"],
    instagramLink: obj["instagramLink"],
    storeLocation: obj["storeLocation"],
    storeLocationLatitude: obj["storeLocationLatitude"],
    storeLocationLongitude: obj["storeLocationLongitude"],
  });

  return store;
};

/**
 * Fetch given production information that is in a given store.
 *
 * @param unique_store_name
 * @param unique_product_name
 * @returns a TypeProduct instance.
 */
export const getProductInfoFromStore = async (
  unique_store_name: string,
  unique_product_name: string
) => {
  const findProductApi =
    process.env.REACT_APP_BACKEND_SERVICE_API +
    "/stores/" +
    unique_store_name +
    "/products/" +
    unique_product_name;
  const res = await fetch(findProductApi);
  const response = await res.json();
  if (response.statusCode === 404) {
    return null;
  }

  const product: TypeProduct = Object.assign(response, {
    productId: response["productId"],
    productName: response["productName"],
    uniqueProductNameInStore: response["uniqueProductNameInStore"],
    productDescription: response["productDescription"],
    priceInDollar: response["priceInDollar"],
    productBrand: response["productBrand"],
    productBrandWebsite: response["productBrandWebsite"],
    productBrandDescription: response["productBrandDescription"],
    productCategory: response["productCategory"],
    productImageUrls: response["productImageUrls"],
    timestamp: response["timestamp"],
  });

  return product;
};

/**
 * Search production in given store.
 *
 * @param unique_store_name
 * @returns an array of TypeProduct
 */
export const getProductsFromStore = async (unique_store_name: string) => {
  const findProductsApi =
    process.env.REACT_APP_BACKEND_SERVICE_API +
    "/stores/" +
    unique_store_name +
    "/products/";
  const res = await fetch(findProductsApi);
  const json = await res.json();
  const productList: TypeProduct[] = json.map((obj: any) => {
    return {
      productId: obj["productId"],
      productName: obj["productName"],
      uniqueProductNameInStore: obj["uniqueProductNameInStore"],
      productDescription: obj["productDescription"],
      priceInDollar: obj["priceInDollar"],
      productBrand: obj["productBrand"],
      productBrandWebsite: obj["productBrandWebsite"],
      productBrandDescription: obj["productBrandDescription"],
      productCategory: obj["productCategory"],
      productImageUrls: obj["productImageUrls"],
      uniqueStoreName: obj["uniqueStoreName"],
      storeName: obj["storeName"],
      timestamp: obj["timestamp"],
    };
  });

  return productList;
};

/**
 * Type for store cateogry.
 */
export type TypeStoreCategory = {
  categoryType: string;
};

/**
 * Get all store categories.
 *
 * @returns an array of TypeStoreCategory.
 */
export const getStoreCategories = async () => {
  const getStoreCategoriesApi =
    process.env.REACT_APP_BACKEND_SERVICE_API + "/store-categories";
  const res = await fetch(getStoreCategoriesApi);
  const response = await res.json();
  const storeCategories: TypeStoreCategory[] = response.map((obj: any) => {
    return {
      categoryType: obj["categoryType"],
    };
  });

  return storeCategories;
};

/**
 * Create a new store.
 *
 * @param postBody
 */
export const createNewStore = async (postBody: any) => {
  const user: CognitoUser | null = getCurrentBusinessAccountUser();
  const session: any = await getCurrentSession(user!);

  const createNewStoreApi =
    process.env.REACT_APP_BACKEND_SERVICE_API + "/stores";

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: session.idToken.jwtToken,
    },
    body: postBody,
  };

  return await fetch(createNewStoreApi, requestOptions);
};

/**
 * Update existing store.
 *
 * @param postBody
 */
export const updateStore = async (uniqueStoreName: string, postBody: any) => {
  const user: CognitoUser | null = getCurrentBusinessAccountUser();
  const session: any = await getCurrentSession(user!);

  const updateStoreApi =
    process.env.REACT_APP_BACKEND_SERVICE_API + "/stores/" + uniqueStoreName;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: session.idToken.jwtToken,
    },
    body: postBody,
  };

  return await fetch(updateStoreApi, requestOptions);
};

/**
 * Create a new product.
 *
 * @param postBody
 */
export const createNewProduct = async (
  uniqueStoreName: string,
  postBody: any
) => {
  const user: CognitoUser | null = getCurrentBusinessAccountUser();
  const session: any = await getCurrentSession(user!);

  const createNewProductApi =
    process.env.REACT_APP_BACKEND_SERVICE_API +
    "/stores/" +
    uniqueStoreName +
    "/products";
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: session.idToken.jwtToken,
    },
    body: postBody,
  };

  return await fetch(createNewProductApi, requestOptions);
};

/**
 * Generate a product identifier for the specified store.
 *
 * @param uniqueStoreName
 * @param productName
 * @returns a product identifier in the specified store.
 */
export const generateProductIdentifierInStore = async (
  uniqueStoreName: string,
  productName: string
) => {
  const apiUrl =
    process.env.REACT_APP_BACKEND_SERVICE_API +
    "/stores/" +
    uniqueStoreName +
    "/operation?operationType=GenerateProductIdentifierInStore&productName=" +
    productName;
  const res = await fetch(apiUrl);
  const response = await res.json();
  if (response.statusCode === 200) {
    return response.productIdentifier;
  }
  return null;
};

