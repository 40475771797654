import React from 'react';
import ReactDOM from "react-dom/client";
import { ReactNotifications} from 'react-notifications-component';
import "react-notifications-component/dist/theme.css";
import { BrowserRouter} from "react-router-dom";
import Nav from "src/components/Nav";
import 'src/assets/styles/output.css'; // Pord release
import App from 'src/App';

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  // https://github.com/google-map-react/google-map-react/issues/1145
  <>
    <ReactNotifications />
    <Nav/>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>,
);