import * as Formik from "formik";
import * as Yup from "yup";
import FormikTextField from "src/components/Common/FormikTextField";
import FormikPasswordField from "src/components/Common/FormikPasswordField";
import FormikTextArea from "src/components/Common/FormikTextArea";
import { URL_REG_MATCH, PHONE_REG_MATCH } from "src/constants";
import { TypeStore, getStoreInfo } from "src/services/StoreService";

const uniqueStoreNameRegMatch = /^[A-Za-z0-9_-]*$/;

const validationSchema = Yup.object().shape({
  uniqueStoreName: Yup.string()
    .required("It's unique name/identifier across the website.")
    .matches(
      uniqueStoreNameRegMatch,
      "Unique store name can only contains alphabets, numbers, understore and hyphen."
    )
    .min(6, "It must be at least 6 characters")
    .max(60, "It must not exceed 60 characters")
    .test(
      "Unique store name validation", // test name
      "The name is taken. Please choose another one.", // Msg
      async (uniqueStoreName) => {
        if (uniqueStoreName) {
          const storeInfo: TypeStore | null = await getStoreInfo(
            uniqueStoreName
          );
          return storeInfo == null;
        } else {
          return true;
        }
      }
    ),
  storeName: Yup.string()
    .required("Store name can also be your brand name.")
    .min(6, "It must be at least 6 characters")
    .max(60, "It must not exceed 60 characters"),
  storeWebsite: Yup.string()
    .matches(URL_REG_MATCH, "Not a valid website url!")
    .required(
      "Provde a website/yelp link so that we can confirm your business."
    ),
  storeDescription: Yup.string()
    .required("A good description for your business will make it prosper.")
    .min(60, "It must be at least 60 characters")
    .max(600, "It must not exceed 600 characters"),
  contactNumber: Yup.string()
    .required(
      "Please provides a valid phone number so that we can verify your information."
    )
    .matches(
      PHONE_REG_MATCH,
      "Please provides a valid phone number so that we can verify your information."
    ),
  contactEmail: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
});

export type TypeCreateStoreInfoForm = {
  uniqueStoreName: string;
  storeName: string;
  storeWebsite: string;
  storeDescription: string;
  contactNumber: string;
  contactEmail: string;
  instagramLink?: string;
  twitterLink?: string;
  facebookLink?: string;
  storeLocation: string;
  storeLocationLongitude?: number;
  storeLocationLatitude?: number;
  password: string;
  confirmPassword: string;
  acceptTerms: false;
};

const CreateStoreInfoForm = ({
  formValues,
  handleSubmit,
}: {
  formValues: TypeCreateStoreInfoForm;
  handleSubmit: any;
}) => {
  return (
    <div className="flex justify-center items-center">
      <Formik.Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ resetForm }) => (
          <Formik.Form className="w-1/3">
            <div className="form-group">
              <FormikTextField label="Store Website" name="storeWebsite" />
            </div>
            <div className="form-group">
              <FormikTextField
                label="Unique Store Name"
                name="uniqueStoreName"
              />
            </div>
            <div>
              <FormikTextField label="Store Name" name="storeName" />
            </div>
            <div className="form-group">
              <FormikTextArea
                label="Store Description"
                name="storeDescription"
                rows="6"
                placeholder="A good description for your business will make it prosper."
              />
            </div>
            <div className="form-group">
              <FormikTextField label="Contact Number" name="contactNumber" />
            </div>
            <div className="form-group">
              <FormikTextField label="Contact Email" name="contactEmail" />
            </div>
            <div className="form-group">
              <FormikTextField
                label="Store Location"
                name="storeLocation"
                placeholder={formValues?.storeLocation}
              />
            </div>
            <div className="form-group">
              <FormikTextField
                label="Store Location Longitude"
                name="storeLocationLongitude"
                placeholder={formValues?.storeLocationLongitude}
              />
            </div>
            <div className="form-group">
              <FormikTextField
                label="Store Location Latitude"
                name="storeLocationLatitude"
                placeholder={formValues?.storeLocationLatitude}
              />
            </div>
            <div className="form-group">
              <FormikTextField
                label="Instagram Link"
                name="instagramLink"
                placeholder={formValues?.instagramLink}
              />
            </div>
            <div className="form-group">
              <FormikTextField
                label="Twitter Link"
                name="twitterLink"
                placeholder={formValues?.twitterLink}
              />
            </div>
            <div className="form-group">
              <FormikTextField
                label="Facebook Link"
                name="facebookLink"
                placeholder={formValues?.facebookLink}
              />
            </div>

            <div className="form-group">
              <FormikPasswordField label="Password" name="password" />
            </div>
            <div className="form-group">
              <FormikPasswordField
                label="Confirm Password"
                name="confirmPassword"
              />
            </div>
            <div className="form-group form-check">
              <Formik.Field
                name="acceptTerms"
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="acceptTerms" className="form-check-label">
                I have read and agree to the Terms
              </label>
              <Formik.ErrorMessage
                name="acceptTerms"
                component="div"
                className="error"
              />
            </div>
            <div className="form-group">
              <button type="submit" className="form-button">
                Register
              </button>
              <button
                type="button"
                onClick={() => resetForm({ values: formValues })}
                className="form-button float-right"
              >
                Reset
              </button>
            </div>
          </Formik.Form>
        )}
      </Formik.Formik>
    </div>
  );
};

export default CreateStoreInfoForm;
