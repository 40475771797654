import { useField, Field, Formik } from "formik";
import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import {
  FilePondFile,
  ProcessServerConfigFunction,
  FilePondErrorDescription,
  ActualFileObject,
} from "filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import axios from "axios";
import { getPresignedUrl } from "src/services/ProductImageService";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

/**
 * FormikFilesUploadField is a custom input primitive built with using useField in formik.
 * 
 * Reference: https://formik.org/docs/api/useField
 * 
 * @param param0 
 * @returns 
 */
const FormikFilesUploadField = ({ label, storeIdentifier, ...props }: any) => {

  const [field, meta] = useField(props);
  const [files, setFiles] = React.useState<ActualFileObject[]>([]);

  return (
    <>
      <label htmlFor={props.id || props.name} className="form-label">
        {label}
      </label>
      <FilePond
        {...field}
        {...props}
        files={files}
        allowMultiple={true}
        allowReorder={true}
        maxFiles={30}
        server={{
          process: (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort,
            transfer,
            options
          ) => {
            // https://pqina.nl/filepond/docs/api/server/#advanced
            getPresignedUrl(storeIdentifier, file.name, 1).then(
              (presignedUrl: string) => {
                // Generate the image URL from assets domain
                let imageUrl = presignedUrl.substring(
                  0,
                  presignedUrl.indexOf("?")
                );
                let position = imageUrl.search("/images");
                imageUrl =
                  process.env.REACT_APP_ASSETS_DOMAIN +
                  imageUrl.substring(position);
                field.value.push(imageUrl);
                files.push(file);
                
                // related to aborting the request
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();

                // the request itself
                axios({
                  method: "put",
                  url: presignedUrl,
                  data: file,
                  headers: {
                    "Content-Type": file.type,
                  },
                  cancelToken: source.token,
                  onUploadProgress: (e) => {
                    // updating progress indicator
                    progress(e.lengthComputable, e.loaded, e.total);
                  },
                })
                  .then((response) => {
                    // passing the file id to FilePond
                    load(file.name);
                  })
                  .catch((thrown) => {
                    if (axios.isCancel(thrown)) {
                      console.log("Request canceled", thrown.message);
                    } else {
                      //console.log(thrown);
                    }
                  });

                // Setup abort interface
                return {
                  abort: () => {
                    source.cancel("Operation canceled by the user.");
                  },
                };
              }
            );
          },
        }}
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default FormikFilesUploadField;
