import ProductEditForm from "src/components/ProductPage/ProductEditForm";

export interface EditProductPagePros {
  storeIdentifier: string;
}

const EditProductPage = (props: EditProductPagePros) => {
  console.log(props);
  return (
    <ProductEditForm 
      storeIdentifier={props.storeIdentifier} />
  );
};

export default EditProductPage;
