import React from "react";

import { useParams } from "react-router-dom";
import StorePageNavbar from "src/components/StorePage/StorePageNavbar";
import { Phone, Email, LocationOn } from "@material-ui/icons";
import { TypeStore, getStoreInfo } from "src/services/StoreService";

const StoreContactUsPage = () => {
  const pathParameters: any = useParams();
  const [store, setStore] = React.useState<TypeStore | null>({} as TypeStore);
  // Get store info.
  React.useEffect(() => {
    const uniqueStoreNameParam: string = pathParameters.unique_store_name;
    getStoreInfo(uniqueStoreNameParam)
      .then((response) => setStore(response))
      .catch((error) => console.log(error));
  }, [setStore]);

  return (
    <>
    <StorePageNavbar store={store!}/>
    <div className="flex justify-center items-center mt-12 ">
      <div className="row">
        <div className="col-md-5 mr-auto">
          <h2 className="text-5xl font-bold">Contact Us</h2>
          <p className="mb-5">
            If you have any questions or comments, please feel free to contact
            us, we will be happy to help you.
          </p>

          <ul className="list-unstyled pl-md-5 mb-5 ">
            {store!.contactNumber && (
              <li className="text-black mb-2">
                <span className="mr-3 font-sans hover:font-serif">
                  <Phone /> Phone:
                </span>{" "}
                {store?.contactNumber}{" "}
              </li>
            )}
            {store!.contactEmail && (
              <li className="text-black">
                <span className="mr-3">
                  <span className="text-black mb-2" /> <Email /> Email:
                </span>{" "}
                {store?.contactEmail}{" "}
              </li>
            )}
            {store!.storeLocation && (
              <li className="text-black mb-2">
                {" "}
                <LocationOn /> {store?.storeLocation}
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
    </>
  );
};

export default StoreContactUsPage;
