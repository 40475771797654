import React from "react";

import * as Formik from "formik";
import * as Yup from "yup";

import FormikTextField from "src/components/Common/FormikTextField";
import FormikTextArea from "src/components/Common/FormikTextArea";
import FormikPriceField from "src/components/Common/FormikPriceField";
import FormikFilesUploadField from "src/components/Common/FormikFilesUploadField";
import { URL_REG_MATCH } from "src/constants";

const validationSchema = Yup.object().shape({
  productName: Yup.string()
    .required("Product name is required.")
    .min(6, "It must be at least 6 characters")
    .max(60, "It must not exceed 60 characters"),

  productDescription: Yup.string()
    .required("A good description for your business will make it prosper.")
    .min(60, "It must be at least 60 characters")
    .max(600, "It must not exceed 600 characters"),

  priceInDollar: Yup.string()
    .required("Price field is required")
    .matches(/^\d{0,8}(\.\d{1,4})?$/, "Please provide a valid price value."),

  productImageUrls: Yup.array()
    .of(Yup.string().matches(URL_REG_MATCH, "Not a valid website url!"))
    .required("Product photo is required"),

  acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
});

export type ProductAttributes = {
  productName: string;
  productDescription: string;
  priceInDollar: number;
  productImageUrls: string[];
  acceptTerms: boolean;
};

export type ProductFormProps = {
  storeIdentifier: string;
  formValues: ProductAttributes;
  handleSubmit: any;
};

const ProductCreateForm = (props: ProductFormProps) => {

  return (
    <div className="flex justify-center items-center">
      <Formik.Formik
        enableReinitialize={true}
        initialValues={props.formValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={props.handleSubmit}
      >
        {({ resetForm }) => (
          <Formik.Form className="w-1/3">
            <div>
              <FormikTextField
                label="Product Name"
                name="productName"
                placeholder={props.formValues.productName}
              />
            </div>
            <div className="form-group">
              <FormikTextArea
                label="Product Description"
                name="productDescription"
                rows="6"
                placeholder={
                  props.formValues.productDescription ||
                  "Precise description for the product can boost customer trust."
                }
              />
            </div>
            <div className="form-group">
              <FormikPriceField
                label="Product Price"
                name="priceInDollar"
                placeholder={props.formValues.priceInDollar || 0}
              />
            </div>
            <div className="form-group">
              <FormikFilesUploadField
                name="productImageUrls"
                label="Upload Images"
                storeIdentifier={props.storeIdentifier}
              />
            </div>
            <div className="form-group form-check">
              <Formik.Field
                name="acceptTerms"
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="acceptTerms" className="form-check-label">
                I have verified that the product information is correct.
              </label>
              <Formik.ErrorMessage
                name="acceptTerms"
                component="div"
                className="error"
              />
            </div>
            <div className="form-group">
              <button type="submit" className="form-button">
                Create
              </button>
              <button
                type="button"
                onClick={() => resetForm({ values: props.formValues })}
                className="form-button float-right"
              >
                Reset
              </button>
            </div>
          </Formik.Form>
        )}
      </Formik.Formik>
    </div>
  );
};

export default ProductCreateForm;
