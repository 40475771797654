import React from "react";

import { useNavigate } from "react-router-dom";
import { Toast } from "src/utils/notifications";
import {
  getProductInfoFromStore,
  createNewProduct,
} from "src/services/StoreService";

import { ProductAttributes } from "src/components/ProductPage/ProductCreateForm";
import ProductCreateForm from "src/components/ProductPage/ProductCreateForm";

export interface CreateProductPagePros {
  storeIdentifier: string;
}

const CreateProductPage = (props: CreateProductPagePros) => {
  let navigate = useNavigate();

  const [formValues, setFormValues] = React.useState<ProductAttributes>({
    productName: "",
    productDescription: "",
    priceInDollar: 0,
    productImageUrls: [],
    acceptTerms: false,
  });

  const handleSubmit = (data: any) => {
    const postBody = JSON.stringify(data, null, 2);

    createNewProduct(props.storeIdentifier, postBody)
      .then((response) => {
        if (response.ok) {
          Toast(
            "Created!",
            "The product information has been uploaded. Redirect to the new product page...",
            "success"
          );
          response.json().then((responseData) => {
            navigate(
              "/stores/" +
                responseData.uniqueStoreName +
                "/products/" +
                responseData.uniqueProductNameInStore
            );
          });
          //history.push("/stores/" + data.uniqueStoreName);
        } else {
          Toast(
            "Failed to create product!",
            "Server is not able to create new product at this moment. Please try later.",
            "danger"
          );
        }
      })
      .catch((error) => {
        Toast("Updated failed!!", error.message, "danger");
      });
  };

  return (
    <>
      <ProductCreateForm
        storeIdentifier={props.storeIdentifier}
        formValues={formValues}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateProductPage;
