import { number } from "yup/lib/locale";

import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import {
  getCurrentBusinessAccountUser,
  getCurrentSession,
} from "src/services/AuthService";

export const getPresignedUrl = async (
  storeIdentifier: string,
  imageName: string,
  imageOrder: number
) => {
  const user: CognitoUser | null = getCurrentBusinessAccountUser();
  const session: any = await getCurrentSession(user!);
  if (storeIdentifier) {
    const urlParameters = {
      storeIdentifier: storeIdentifier,
      imageName: imageName,
      imageOrder: imageOrder.toString(),
    };
    const getPresignedApi =
      process.env.REACT_APP_BACKEND_SERVICE_API +
      "/product-image-assets?" +
      new URLSearchParams(urlParameters).toString();
    const response = await fetch(getPresignedApi, {
      headers: new Headers({
        Authorization: session.idToken.jwtToken,
      }),
    });
    const json = await response.json();
    //console.log(json);
    return json.presignedUrl;
  } else {
    throw new Error("storeIdentifier is null or undefined!");
  }
};
