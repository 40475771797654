import React from "react";
import { Loader } from "@googlemaps/js-api-loader";

const FormAddressField = ({
  label,
  value,
  register,
  onAddressUpdate,
  ...props
}: any) => {
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
    libraries: ["places", "geometry"],
  });

  const locationInputId = "locationInputId";
  let searchInput: HTMLInputElement;
  const autoCompleteInstanceRef = React.useRef<any>(null);

  React.useEffect(() => {
    loader.load().then(() => {
      let searchInput = document.getElementById(
        locationInputId
      ) as HTMLInputElement;
      //console.log(searchInput);
      autoCompleteInstanceRef.current = new google.maps.places.Autocomplete(
        searchInput!,
        {
          // restrict your search to a specific type of resultmap
          //types: ["address"],
          // restrict your search to a specific country, or an array of countries
          // componentRestrictions: { country: ['gb', 'us'] },
        }
      );

      autoCompleteInstanceRef.current.addListener(
        "place_changed", // cant' change this value
        onPlaceChanged
      );
    });

    // returned function will be called on component unmount

    /*
    return () => {
      google.maps.event.clearInstanceListeners(searchInput!);
    };
    */
  }, []);

  const onPlaceChanged = () => {
    const place: google.maps.places.PlaceResult =
      autoCompleteInstanceRef.current.getPlace();

    if (!place) return;
    console.log(place.formatted_address);
    onAddressUpdate(place.formatted_address);
  };

  return (
    <>
      <label htmlFor={props.id || props.name} className="form-label">
        {label}
      </label>
      <input
        {...register("storeLocation")}
        value={value}
        id={locationInputId}
        className="text-md w-full h-full m-0 p-0"
        type="text"
      />
    </>
  );
};

export default FormAddressField;
