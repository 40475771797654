import React from "react";

import { useParams } from "react-router-dom";
import { Add, Remove } from "@material-ui/icons";
import styled from "styled-components";
import ProductPageNavbar from "src/components/ProductPage/ProductPageNavBar";
import { mobile } from "../responsive";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { getProductInfoFromStore, TypeStore, getStoreInfo} from "src/services/StoreService";
import {TypeProduct} from "src/services/ProductService";

const ProductInfoContainer = ({product} : {product : TypeProduct}) => {
  return (
      <div className="items-center ml-20">
        <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-400 via-purple-400 to-red-400">{product.productName}</h1>
        <p className="mt-8 text-base col-span-full">{product.productDescription}</p>
        <div className="mt-8 text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400  via-yellow-600 to-yellow-800">$ {product.priceInDollar}</div>
      </div>
  );
}

export interface ProductPageProps {
  storeIdentifier: string;
}

const Product = (props: ProductPageProps) => {

  const pathParameters: any = useParams();
  const [product, setProduct] = React.useState<TypeProduct | null>({} as TypeProduct);
  const [store, setStore] = React.useState<TypeStore | null>({} as TypeStore);

    // Get products for this store.
  React.useEffect(
      () => {
          const productIdentifier:string = pathParameters.productIdentifier;
          getProductInfoFromStore(props.storeIdentifier, productIdentifier)
              .then(response => setProduct(response))
              .catch(error => console.log(error));
      }, 
      []
  );

  // Get store info.
  React.useEffect(
      () => {
          getStoreInfo(props.storeIdentifier)
              .then(response => setStore(response))
              .catch(error => console.log(error));  
      }, 
      []
  );

  const imageGalleryItems: ReactImageGalleryItem[] | null = product && product.productImageUrls && product.productImageUrls.map(
    (imgUrl : string ) : ReactImageGalleryItem => {
      return {
        original: imgUrl,
        thumbnail: imgUrl
      };
    }
  );

  return (
    <div>
      <ProductPageNavbar store={store!} product={product!} />

      <div className="flex">
        { imageGalleryItems &&
         <ImageGallery items={imageGalleryItems} />
        }
        { product &&
          <ProductInfoContainer product={product}/>
        }
      </div>
    </div>
  );
};

export default Product;
