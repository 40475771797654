import React from "react";
import { Toast } from "src/utils/notifications";
import { CognitoUser } from "amazon-cognito-identity-js";
import { getCurrentBusinessAccountUser } from "src/services/AuthService";
import { TypeStore, getStoreInfo } from "src/services/StoreService";

const StoreInfoField = ({ field, value }: { field: string; value: string }) => {
  return (
    <div className="col-span-full">
      <div className=" text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-400 via-purple-400  to-red-400 ">
        {field}
      </div>
      <div className="text-base bg-clip-text bg-gradient-to-r">{value}</div>
    </div>
  );
};

const OperationLink = ({
  linkUrl,
  linkText,
}: {
  linkUrl: string;
  linkText: string;
}) => {
  return (
    <a
      href={linkUrl}
      className="hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600 block"
    >
      {linkText}
    </a>
  );
};

const EditSection = ({ uniqueStoreName }: { uniqueStoreName: string }) => {
  return (
    <div className="absolute top-0 right-7">
      <OperationLink
        linkUrl={process.env.REACT_APP_DOMAIN + "/stores/" + uniqueStoreName}
        linkText="Visit Store"
      />
      <OperationLink
        linkUrl={
          process.env.REACT_APP_DOMAIN +
          "/stores/" +
          uniqueStoreName +
          "/edit"
        }
        linkText="Edit Store Infomation"
      />
      <OperationLink
        linkUrl={
          process.env.REACT_APP_DOMAIN +
          "/stores/" +
          uniqueStoreName +
          "/products/create"
        }
        linkText="Upload Product"
      />
    </div>
  );
};

const ViewStoreInfoPage = () => {
  
  const [store, setStore] = React.useState<TypeStore | null>({} as TypeStore);

  // Get store info.
  React.useEffect(() => {
    const user: CognitoUser | null = getCurrentBusinessAccountUser();

    if (!user) {
      Toast("Store Not Found!", "Failed to get store information!", "danger");
    } else {
      const uniqueStoreNameParam: string = user?.getUsername();
      getStoreInfo(uniqueStoreNameParam)
        .then((response) => setStore(response))
        .catch((error) => console.log(error));
    }
      
  }, [setStore]);

  return (
    <div>
      <EditSection uniqueStoreName={store!.uniqueStoreName} />
      <div className="flex justify-center items-center text-3xl text-transparent bg-clip-text bg-gradient-to-r from-pink-400 via-red-400 to-purple-600 ">
        {store!.uniqueStoreName}
      </div>
      <div className="grid grid-rows-3 grid-flow-col gap-4 justify-center items-center ">
        <StoreInfoField
          field="uniqueStoreName"
          value={store?.uniqueStoreName || ""}
        />
        <StoreInfoField field="storeName" value={store?.storeName || ""} />
        <StoreInfoField
          field="storeWebsite"
          value={store?.storeWebsite || ""}
        />
        <StoreInfoField
          field="contactEmail"
          value={store?.contactEmail || ""}
        />
        <StoreInfoField
          field="contactNumber"
          value={store?.contactNumber || ""}
        />
        <StoreInfoField
          field="storeDescription"
          value={store?.storeDescription || ""}
        />
        <StoreInfoField
          field="instagramLink"
          value={store?.instagramLink || ""}
        />
        <StoreInfoField
          field="facebookLink"
          value={store?.facebookLink || ""}
        />
        <StoreInfoField
          field="twitterLink"
          value={store?.twitterLink || ""}
        />
        <StoreInfoField
          field="storeLocation"
          value={store?.storeLocation || ""}
        />
        <StoreInfoField
          field="storeLocationLatitude"
          value={store?.storeLocationLatitude?.toString() || ""}
        />
        <StoreInfoField
          field="storeLocationLongitude"
          value={store?.storeLocationLongitude?.toString() || ""}
        />


      </div>
    </div>
  );
};

export default ViewStoreInfoPage;
