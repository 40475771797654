import {useField} from "formik";

/**
 * FormikTextArea is a custom input primitive built with using useField in formik.
 * 
 * Reference: https://formik.org/docs/api/useField
 * 
 * @param param0 
 * @returns 
 */
const FormikTextArea = ({ label, ...props }: any) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name} className="form-label">
          {label}
        </label>
        <textarea
          className="text-area border rounded py-2 px-3 text-gray-700 leading-tight w-full"
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  };

  export default FormikTextArea;