import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoUserAttribute,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

import { getUser, getPool, signOut, getSession } from "src/utils/cognito";

/***********************************************************
 * Business account related
 *************************************************************/

/**
 * obtains current cognito user from the current pool
 * @return {CognitoUser} Cognito user object
 */
export function getCurrentBusinessAccountUser(): CognitoUser | null {
  return getUser(
    process.env.REACT_APP_BUSINESS_ACCOUNT_USER_POOL_ID!,
    process.env.REACT_APP_BUSINESS_ACCOUNT_USER_POOL_CLIENT_ID!
  );
}

/**
 * obtains current cognito user from the current pool
 * @return {CognitoUser} Cognito user object
 */
 export function getCurrentSession(user: CognitoUser) {
  return getSession(user);
}


/**
 * creates a cognito user object from the following email and current pool
 * @param {string} email - user email
 * @return {CognitoUser} CognitoIdentity object
 */
function createNewBusinessAccount(email: string) {
  let userData = {
    Username: email,
    Pool: getPool(
      process.env.REACT_APP_BUSINESS_ACCOUNT_USER_POOL_ID!,
      process.env.REACT_APP_BUSINESS_ACCOUNT_USER_POOL_CLIENT_ID!
    ),
  };
  return new CognitoUser(userData);
}

/**
 * execute the Sign Up action
 * @param {string} email - the user email
 * @param {string} password - the user password
 * @return {Promise<Object>} the recently created user object
 */
export function signUpBusinessAccount(email: string, password: string) {
  //take credentials, and pool and return either an user or an
  var userAttributes = [
    new CognitoUserAttribute({
      Name: "email",
      Value: email,
    }),
  ];
  var validationData: CognitoUserAttribute[] = [];
  return new Promise((resolve, reject) => {
    getPool(
      process.env.REACT_APP_BUSINESS_ACCOUNT_USER_POOL_ID!,
      process.env.REACT_APP_BUSINESS_ACCOUNT_USER_POOL_CLIENT_ID!
    ).signUp(
      email,
      password,
      userAttributes,
      validationData,
      function (err: any, result: any) {
        if (err) {
          reject(err);
          return;
        }
        resolve(result.user);
      }
    );
  });
}

/**
 * execute the Sign In action
 * @param {string} email - the user email
 * @param {string} password - the user password
 * @param {CognitoUser} user - the CognitoUser object to sign in
 * @return {Promise<Object>} the session object
 */
export function signInBusinessAccount(email: string, password: string) {
  var authenticationData = {
    Username: email,
    Password: password,
  };
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: getPool(
      process.env.REACT_APP_BUSINESS_ACCOUNT_USER_POOL_ID!,
      process.env.REACT_APP_BUSINESS_ACCOUNT_USER_POOL_CLIENT_ID!
    ),
  });
  var authenticationDetails = new AuthenticationDetails(authenticationData);
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => resolve(result),
      onFailure: (error) => reject(error),
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        resolve({ needResetPassword: true, cognitoUser, userAttributes });
      },
    });
  });
}

export function resetPasswordForBusinessAccount(
  cognitoUser: CognitoUser,
  newPassword: string
) {
  console.log(cognitoUser);
  return new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      {},
      {
        onSuccess: (result) => resolve(result),
        onFailure: (error) => reject(error),
      }
    );
  });
}


export function signOutUser(cognitoUser: CognitoUser) {
  signOut(cognitoUser);
}