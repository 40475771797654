
import EditStoreInfoForm, {
  StoreAttributes,
} from "src/components/StorePage/EditStoreInfoForm";

type EditStoreInfoPageProps = {
  storeIdentifier: string;
};
const EditStoreInfoPage = (props: EditStoreInfoPageProps) => {
  return (
    <EditStoreInfoForm
      storeIdentifier={props.storeIdentifier}
    />
  );
};

export default EditStoreInfoPage;
