import React from "react";

import { openInNewTab } from "src/utils/helperFunctions";
import { TypeStore } from "src/services/StoreService";
import { confirmAlert } from "react-confirm-alert";
import {Toast} from "src/utils/notifications";
import "react-confirm-alert/src/react-confirm-alert.css";
import styled from "styled-components";
import { mobile } from "../../responsive";
import { Facebook, Instagram, Twitter } from "@material-ui/icons";
import { TypeProduct } from "src/services/ProductService";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";

const ProductPageNavContainer = ({
  store,
  product,
}: {
  store: TypeStore;
  product: TypeProduct;
}) => {
  return (
    <div>
      <ProductPageNavWrapper store={store} product={product} />
    </div>
  );
};

const ProductPageNavWrapper = ({
  store,
  product,
}: {
  store: TypeStore;
  product: TypeProduct;
}) => {
  return (
    <div className="flex justify-content justify-between  border-b-4  border-blue-300 p-3">
      <LeftNav />
      <CenterNav store={store} />
      <RightNav store={store} product={product} />
    </div>
  );
};

const LeftNav = () => {
  return <div className="flex items-center w-1/5"></div>;
};

const CenterNav = ({ store }: { store: TypeStore }) => {
  return (
    <div className="items-center justify-center w-3/5">
      <Logo logo={store.storeName} unique_store_name={store.uniqueStoreName} />
      <SocialContainer store={store} />
      <div> {store.storeDescription} </div>
    </div>
  );
};

const OperationLink = ({
  linkUrl,
  linkText,
  onClick,
}: {
  linkUrl?: string;
  linkText: string;
  onClick?: any;
}) => {
  let navigate = useNavigate();

  const onClickFunction = () => {
    if (onClick) {
      onClick();
    } else {
      //console.log(linkUrl);
      if(linkUrl) {
        navigate(linkUrl);
      }
    }
  }

  return (
    <a
      onClick={onClickFunction}
      className="hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600 block"
    >
      {linkText}
    </a>
  );
};

const RightNav = ({
  store,
  product,
}: {
  store: TypeStore;
  product: TypeProduct;
}) => {
  let navigate = useNavigate();
  const deleteProductAPI=
    process.env.REACT_APP_BACKEND_SERVICE_API +
    "/stores/" +
    store.uniqueStoreName +
    "/products/" +
    product.uniqueProductNameInStore;
  const deleteProductOnClick = () => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios.delete(deleteProductAPI, {
              headers: {
                //Authorization: authorizationToken
              },
              /*
              data: {
                source: source
              }
              */
            }).then(
              response => {
                Toast(
                  "Deleted!",
                  "The product has been deleted. Redirect to store page...",
                  "success"
                );
                navigate('../');
              }
            ); 
          }
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    });
  }  

  return (
    <div className="flex items-center justify-end w-1/5">
      <div>
        <OperationLink linkUrl="edit" linkText="Edit Product" />
        <OperationLink onClick={deleteProductOnClick} linkText="Delete Product" />
      </div>
    </div>
  );
};

const Logo = ({
  logo,
  unique_store_name,
}: {
  logo: string;
  unique_store_name: string;
}) => {
  return (
    <div
      className="flex items-center justify-center text-3xl cursor-pointer hover:opacity-60"
      onClick={() =>
        window.open(
          process.env.REACT_APP_DOMAIN + "/stores/" + unique_store_name,
          "_self"
        )
      }
    >
      {logo}
    </div>
  );
};

const SocialContainer = ({ store }: { store: TypeStore }) => {
  return (
    <div className="flex items-center justify-center">
      {store.facebookLink && (
        <div
          className="w-10 h-10 mr-5 text-blue-500  hover:opacity-60 hover:scale-125"
          onClick={(event) => openInNewTab(store.facebookLink)}
        >
          <Facebook />
        </div>
      )}
      {store.instagramLink && (
        <div
          className="w-10 h-10 mr-5 text-pink-500 hover:opacity-60 hover:scale-125"
          onClick={(event) => openInNewTab(store.instagramLink)}
        >
          <Instagram />
        </div>
      )}
      {store.twitterLink && (
        <div
          className="w-10 h-10 mr-5 text-blue-700 hover:opacity-60 hover:scale-125"
          onClick={(event) => openInNewTab(store.twitterLink)}
        >
          <Twitter />
        </div>
      )}
    </div>
  );
};

const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;

const ProductPageNavbar = ({
  store,
  product,
}: {
  store: TypeStore;
  product: TypeProduct;
}) => {
  return <ProductPageNavContainer store={store} product={product} />;
};

export default ProductPageNavbar;
