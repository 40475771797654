import ProductCard from "src/components/SearchProductPage/ProductCard";
import {TypeProduct} from "src/services/ProductService";

const ProductsContainer = ({productList} : {productList: TypeProduct[]}) => {
  return (
    <div className="content-start p-5 flex flex-wrap mb-40">
       {
        productList.map(
          (product) => ( 
            <ProductCard 
              product={product} 
              key={product.productId} 
              cardLink={process.env.REACT_APP_DOMAIN + "/stores/" 
                      + product.uniqueStoreName + "/products/" + product.uniqueProductNameInStore}
              />
            )
        )
      }
    </div>
  );
}

const Products = ({productList} : {productList: TypeProduct[]}) => {
  return (
    <ProductsContainer productList={productList}/>
  );
};

export default Products;
