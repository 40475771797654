import React from "react";
import { FieldValue, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { Toast } from "src/utils/notifications";
import { CognitoUser } from "amazon-cognito-identity-js";
import { getCurrentBusinessAccountUser } from "src/services/AuthService";
import { getStoreInfo, updateStore } from "src/services/StoreService";
import { URL_REG_MATCH, PHONE_REG_MATCH } from "src/constants";
import FormAddressField from "src/components/Common/FormAddressField";
import StoresGoogleMapLocation from "src/components/StorePage/StoreGoogleMapLocation";

const validationSchema = Yup.object().shape({
  storeName: Yup.string()
    .required("Store name can also be your brand name.")
    .min(6, "It must be at least 6 characters")
    .max(60, "It must not exceed 60 characters"),
  storeWebsite: Yup.string()
    .matches(URL_REG_MATCH, "Not a valid website url!")
    .required(
      "Provde a website/yelp link so that we can confirm your business."
    ),
  storeDescription: Yup.string()
    .required("A good description for your business will make it prosper.")
    .min(60, "It must be at least 60 characters")
    .max(600, "It must not exceed 600 characters"),
  storeLocation: Yup.string()
    .required("Please provide store location.")
    .min(10, "Store location must be at least 10 characters"),
  storeLocationLatitude: Yup.number().required("Store location latitude is not correct"),
  storeLocationLongitude: Yup.number().required("Store location longitude is not correct"),
  contactNumber: Yup.string()
    .required(
      "Please provides a valid phone number so that we can verify your information."
    )
    .matches(
      PHONE_REG_MATCH,
      "Please provides a valid phone number so that we can verify your information."
    ),
  contactEmail: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

export type StoreAttributes = {
  storeName: string;
  storeWebsite: string;
  storeDescription: string;
  contactNumber: string;
  contactEmail: string;
  instagramLink?: string;
  twitterLink?: string;
  facebookLink?: string;
  storeLocation: string;
  storeLocationLongitude: number;
  storeLocationLatitude: number;
  acceptTerms: false;
};

export type EditStoreInfoFormProps = {
  storeIdentifier: string;
};

/**
 * Return a form component for store information update.
 *
 * @param props
 *
 * @returns
 */
const EditStoreInfoForm = (props: EditStoreInfoFormProps) => {
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const storeLocationLatitude = watch("storeLocationLatitude");
  const storeLocationLongitude = watch("storeLocationLongitude");

  // Get store info.
  React.useEffect(() => {
    const user: CognitoUser | null = getCurrentBusinessAccountUser();

    if (!user) {
      Toast("Store Not Found!", "Failed to get store information!", "danger");
    } else {
      const storeIdentifier: string = user?.getUsername();
      getStoreInfo(storeIdentifier)
        .then((response) => {
          reset({
            storeName: response?.storeName || "",
            storeWebsite: response?.storeWebsite || "",
            storeDescription: response?.storeDescription || "",
            contactNumber: response?.contactNumber || "",
            contactEmail: response?.contactEmail || "",
            instagramLink: response?.instagramLink || "",
            twitterLink: response?.twitterLink || "",
            facebookLink: response?.facebookLink || "",
            storeLocation: response?.storeLocation || "",
            storeLocationLatitude: response?.storeLocationLatitude!,
            storeLocationLongitude: response?.storeLocationLongitude!,
          });
        })
        .catch((error) =>
          Toast(
            "Store Not Found!",
            "Failed to get store information!",
            "danger"
          )
        );
    }
  }, []);

  const handleStoreLocationUpdate = (
    newStoreLocation: string,
    values: any
  ) => {
    const geocoder = new window.google.maps.Geocoder();
    const geocodeRequest = { address: newStoreLocation };
    const geocodeCallback = (
      results: google.maps.GeocoderResult[] | null,
      status: google.maps.GeocoderStatus
    ) => {
      if (status === "OK") {
        if (results && results[0]) {
          setValue("storeLocation", newStoreLocation);
          setValue("storeLocationLatitude", results[0].geometry.location.lat());
          setValue("storeLocationLongitude", results[0].geometry.location.lng());
        } else {
          Toast("Not valid address!", "Please input a valid address", "danger");
        }
      } else {
        Toast("Not valid address!", "Please input a valid address", "danger");
      }
    };

    geocoder.geocode(geocodeRequest, geocodeCallback);
  };

  const onSubmit = (data: any) => {
    updateStore(props.storeIdentifier, JSON.stringify(data, null, 2))
      .then((response) => {
        if (response.status == 200) {
          Toast(
            "Updated!",
            "The store information has been updated. Redirect to store page...",
            "success"
          );
          navigate("/stores/" + props.storeIdentifier);
        } else {
          Toast(
            "Updated failed!",
            "Failed to update store information.",
            "danger"
          );
        }
      })
      .catch((error) => {
        Toast("Updated failed!!", error.message, "danger");
      });
  };

  return (
    <div className="flex justify-center items-center">
      <form onSubmit={handleSubmit(onSubmit)} className="form w-2/3">
        <div className="form-group">
          <label className="form-label">Store Website</label>
          <input
            {...register("storeWebsite")}
            type="text"
            className="form-control form-textInput"
          />
          {errors.storeWebsite && (
            <p className="error">{errors.storeWebsite.message?.toString()}</p>
          )}
        </div>
        <div>
          <label className="form-label">Store Name</label>
          <input
            {...register("storeName")}
            type="text"
            className="form-control form-textInput"
          />
          {errors.storeName && (
            <p className="error">{errors.storeName.message?.toString()}</p>
          )}
        </div>
        <div className="form-group">
          <label className="form-label">Store Description</label>
          <textarea
            {...register("storeDescription")}
            className="form-control form-textInput"
          />
          {errors.storeDescription && (
            <p className="error">
              {errors.storeDescription.message?.toString()}
            </p>
          )}
        </div>
        <div className="form-group">
          <label className="form-label">Contact Number</label>
          <input
            {...register("contactNumber")}
            className="form-control form-textInput"
          />
          {errors.contactNumber && (
            <p className="error">{errors.contactNumber.message?.toString()}</p>
          )}
        </div>
        <div className="form-group">
          <label className="form-label">Contact Email</label>
          <input
            {...register("contactEmail")}
            className="form-control form-textInput"
          />
          {errors.contactEmail && (
            <p className="error">{errors.contactEmail.message?.toString()}</p>
          )}
        </div>

        <div className="form-group">
          <label className="form-label">Instagram Link</label>
          <input
            {...register("instagramLink")}
            type="text"
            className="form-control form-textInput"
          />
          {errors.instagramLink && (
            <p className="error">{errors.instagramLink.message?.toString()}</p>
          )}
        </div>
        <div className="form-group">
          <label className="form-label">Twitter Link</label>
          <input
            {...register("twitterLink")}
            type="text"
            className="form-control form-textInput"
          />
          {errors.twitterLink && (
            <p className="error">{errors.twitterLink.message?.toString()}</p>
          )}
        </div>
        <div className="form-group">
          <label className="form-label">Facebook Link</label>
          <input
            {...register("facebookLink")}
            type="text"
            className="form-control form-textInput"
          />
          {errors.facebookLink && (
            <p className="error">{errors.facebookLink.message?.toString()}</p>
          )}
        </div>
        <div className="form-group">
          <FormAddressField
            label="Store Location"
            name="storeLocation"
            register={register}
            onAddressUpdate={(newAddress: string) => {
              handleStoreLocationUpdate(newAddress, getValues())
            }}
          />
        </div>
        
        <div className="w-full  h-60">
          <StoresGoogleMapLocation
            googleMapCenter={{
              lat: getValues("storeLocationLatitude"),
              lng: getValues("storeLocationLongitude"),
            }}
            storeAddress={getValues("storeLocation")}
            storeLocationLongitude={getValues("storeLocationLongitude")}
            storeLocationLatitude={getValues("storeLocationLatitude")}
          />
        </div>

        <div className="form-group">
          <button type="submit" className="form-button m-2 w-20 h-10">
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditStoreInfoForm;
