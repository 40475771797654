import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import {
  getCurrentBusinessAccountUser,
  getCurrentSession,
} from "src/services/AuthService";

export type TypeProduct = {
  productId: string;
  productName: string;
  uniqueProductNameInStore: string;
  uniqueStoreName: string;
  storeName: string;
  productDescription: string;
  priceInDollar: number;
  productBrand: string;
  productBrandWebsite: string;
  productBrandDescription: string;
  productCategory: string;
  productImageUrls: string[];
  timestamp: string;
};

export const searchProducts = async () => {
  const searchProductsApi =
    process.env.REACT_APP_BACKEND_SERVICE_API + "/products/";
  const res = await fetch(searchProductsApi);
  const json = await res.json();
  const productList: TypeProduct[] = json.map((obj: any) => {
    return {
      productId: obj["productId"],
      productName: obj["productName"],
      uniqueProductNameInStore: obj["uniqueProductNameInStore"],
      uniqueStoreName: obj["uniqueStoreName"],
      storeName: obj["storeName"],
      productDescription: obj["productDescription"],
      priceInDollar: obj["priceInDollar"],
      productBrand: obj["productBrand"],
      productBrandWebsite: obj["productBrandWebsite"],
      productBrandDescription: obj["productBrandDescription"],
      productCategory: obj["productCategory"],
      productImageUrls: obj["productImageUrls"],
      timestamp: obj["timestamp"],
    };
  });

  return productList;
};

export const updateProduct = async (
  storeIdentifier: string,
  productIdentifier: string,
  postBody: any
) => {
  const updateProductApi =
    process.env.REACT_APP_BACKEND_SERVICE_API +
    "/stores/" +
    storeIdentifier +
    "/products/" + productIdentifier

  const user: CognitoUser | null = getCurrentBusinessAccountUser();
  const session: any = await getCurrentSession(user!);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: session.idToken.jwtToken,
    },
    body: postBody,
  };

  return await fetch(updateProductApi, requestOptions);
};
