import {useField, Field} from "formik";

/**
 * FormikPriceField is a custom input primitive built with using useField in formik.
 * 
 * Reference: https://formik.org/docs/api/useField
 * 
 * @param param0 
 * @returns 
 */
const FormikPriceField = ({ label, ...props }: any) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name} className="form-label">
          {label}
        </label>
        <span className="mr-1">$</span> 
        <Field
          type="number"
          className="form-control form-numberInput"
          {...field}
          {...props}
        /> 
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  };

  export default FormikPriceField;
  