import React from "react";

import { Toast } from "src/utils/notifications";
import {
  signInBusinessAccount,
  resetPasswordForBusinessAccount,
} from "src/services/AuthService";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const SigninForm = () => {
  const [needResetPassword, setNeedResetPassword] = React.useState(false);
  const [cognitoUser, setCognitoUser] = React.useState<CognitoUser | null>(
    null
  );

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter email."),

    password: Yup.string().required("Please enter password."),
  });

  const initialValues = {
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = async (event: any) => {
    try {
      let cognitoResponse: any = null;
      if (!needResetPassword) {
        cognitoResponse = await signInBusinessAccount(
          event.email,
          event.password
        );
      } else {
        cognitoResponse = await resetPasswordForBusinessAccount(
          cognitoUser!,
          event.newPassword
        );
      }

      if (cognitoResponse.needResetPassword) {
        setNeedResetPassword(true);
        setCognitoUser(cognitoResponse.cognitoUser);
        Toast("Password reset required", "You need reset password", "success");
      } else {
        Toast("Success!!", "Login Successfully", "success");
        navigate("/");
      }
    } catch (error: any) {
      console.log(error);
      switch (error.code) {
        case "UserNotFoundException":
          Toast("Error!!", error.message, "danger");
          return;
        case "NotAuthorizedException":
          Toast("Error!!", error.message, "danger");
          return;
        default:
          Toast("Error!!", error.message, "danger");
          return;
      }
    }
  };

  return (
    <>
      <div className="flex h-screen">
        <div className="m-auto  w-1/3">
          <div className="block">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, handleChange, handleBlur, values, errors }) => {
                return (
                  <Form>
                    <div className="form-group w-full">
                      <label htmlFor="email" className="form-label">
                        {" "}
                        Email{" "}
                      </label>
                      <input
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-textInput w-full"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {!needResetPassword ? (
                      <div className="form-group w-full">
                        <label htmlFor="password" className="form-label">
                          {" "}
                          Password{" "}
                        </label>
                        <input
                          name="password"
                          type="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-textInput w-full"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    ) : (
                      <>
                        <div className="form-group">
                          <label htmlFor="newPassword" className="form-label">
                            {" "}
                            Password{" "}
                          </label>
                          <input
                            name="newPassword"
                            type="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-textInput w-full"
                          />
                          <ErrorMessage
                            name="newPassword"
                            component="div"
                            className="error"
                          />
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            {" "}
                            Confirm Password{" "}
                          </label>
                          <input
                            name="confirmPassword"
                            type="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-textInput w-full"
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="error"
                          />
                        </div>
                      </>
                    )}

                    <div className="form-group my-6 flex items-center justify-center">
                      <button type="submit" className="form-submitButton">
                        Login to Your Account
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default SigninForm;
