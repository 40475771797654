import { motion, Variants } from "framer-motion";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ClassNames } from "@emotion/react";

const menuItemVariants: Variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
      ease: [0.6, 0.05, -0.01, 0.9],
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      duration: 0.4,
      ease: [0.6, 0.05, -0.01, 0.9],
    },
  },
};

export type MenuItemProps = {
  text: string;
  icon: any;
  onClick: Function;
};

const MenuItem = ({item}: {item:MenuItemProps}) => {
  return (
    <motion.li variants={menuItemVariants} className="flex items-center mb-5 space-x-6 cursor-pointer">
      <span className="text-placeholder underline" onClick={()=>item.onClick()}>{item.text}</span>
    </motion.li>
  );
};

export default MenuItem;
