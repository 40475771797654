import React from "react";
import { useParams } from "react-router-dom";
import Announcement from "src/components/Announcement";
import StorePageNavbar from "src/components/StorePage/StorePageNavbar";
import ProductsList from "src/components/SearchProductPage/ProductList";
import {TypeStore, getStoreInfo, getProductsFromStore} from "src/services/StoreService";
import {TypeProduct} from "src/services/ProductService";


export interface StorePageProps {
  storeIdentifier: string;
}

const StorePage = (props: StorePageProps) => {
  
  const [store, setStore] = React.useState<TypeStore | null>({} as TypeStore);
  const [products, setProducts] = React.useState<TypeProduct[]>([]);

    // Get products for this store.
    React.useEffect(
        () => {
            getProductsFromStore(props.storeIdentifier)
                .then(response => setProducts(response))
                .catch(error => console.log(error));    

        }, 
        []
    );

    // Get store info.
    React.useEffect(
        () => {
            getStoreInfo(props.storeIdentifier)
                .then(response => setStore(response))
                .catch(error => console.log(error));  
        }, 
        []
    );

  return (
      <div>
      {store &&<StorePageNavbar store={store}/>}
        <Announcement />
        <ProductsList productList = {products}/>
      </div>
    
  );
};

export default StorePage;
