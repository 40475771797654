import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import CreateStorePage from "src/pages/CreateStorePage";
import ViewStoreInfoPage from "src/pages/ViewStoreInfoPage";
import EditStoreInfoPage from "src/pages/EditStoreInfoPage";
import CreateProductPage from "src/pages/CreateProductPage";
import EditProductPage from "src/pages/EditProductPage";
import LandingPage from "src/pages/LandingPage";
import StorePage from "src/pages/StorePage";
import StoreContactUsPage from "src/pages/StoreContactUsPage";
import ProductPage from "src/pages/ProductPage";
import SignupPage from "src/pages/SignupPage";
import SigninPage from "src/pages/SigninPage";
import NotFoundPage from "src/pages/NotFoundPage";
import { getCurrentBusinessAccountUser } from "src/services/AuthService";

export type RouteType = {
  path: string;
  key: string;
  exact: boolean;
  element: React.ReactNode;
};

export const UNPROTECTED_ROUTES: RouteType[] = [
  {
    path: "/",
    key: "LANDING_PAGE",
    exact: true,
    element: <LandingPage />,
  }
];

export const AUTHENTICATION_ROUTES: RouteType[] = [
  {
    path: "/signup",
    key: "SIGNUP_PAGE",
    exact: true,
    element: <SignupPage />,
  },
  {
    path: "/signin",
    key: "SIGNIN_PAGE",
    exact: true,
    element: <SigninPage />,
  },
];

const ADMIN_ROUTES: RouteType[] = [
  {
    path: "/stores/create",
    key: "CREATE_STORE_PAGE",
    exact: true,
    element: <CreateStorePage />,
  },
];

function getStoreAccessableRoutes(storeIdentifier: string) {
  return [
    {
      path: `/stores/${storeIdentifier}`,
      key: "STORE_INFO_PAGE",
      exact: true,
      element: <StorePage storeIdentifier={storeIdentifier}/>,
    },
    {
      path: `/stores/${storeIdentifier}/edit`,
      key: "EDIT_STORE_INFO_PAGE",
      exact: true,
      element: <EditStoreInfoPage storeIdentifier={storeIdentifier}/>,
    },
    {
      path: `/stores/${storeIdentifier}/info`,
      key: "STORE_PAGE",
      exact: true,
      element: <ViewStoreInfoPage/>,
    },
    {
      path: `/stores/${storeIdentifier}/contact-us`,
      key: "STORE_PAGE",
      exact: true,
      element: <StoreContactUsPage/>,
    },
    {
      path: `/stores/${storeIdentifier}/products/create`,
      key: "CREATE_PRODUCT_PAGE",
      exact: true,
      element: <CreateProductPage storeIdentifier={storeIdentifier}/>,
    },
    {
      path: `/stores/${storeIdentifier}/products/:productIdentifier`,
      key: "PRODUCT_PAGE",
      exact: true,
      element: <ProductPage storeIdentifier={storeIdentifier}/>,
    },
    {
      path: `/stores/${storeIdentifier}/products/:productIdentifier/edit`,
      key: "Edit Product Page",
      exact: true,
      element: <EditProductPage storeIdentifier={storeIdentifier}/>,
    },
    
  ];
}

export type RenderRoutesProps = {
  routes: RouteType[];
};

export function RenderRoutes() {
  const [routes, setRoutes] = React.useState<RouteType[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    //user = await CognitoUtils.getCurrentUser();
    const user: CognitoUser | null = getCurrentBusinessAccountUser();
    let newRoutes = UNPROTECTED_ROUTES;
    if (user) {
      //console.log(user.getUsername());
      newRoutes = newRoutes.concat(getStoreAccessableRoutes(user.getUsername()));
    } else {
      newRoutes = newRoutes.concat(AUTHENTICATION_ROUTES);
      navigate("/signin");
    }
    newRoutes.push({
      path: "*",
      key: "NOT_FOUND",
      exact: true,
      element: <NotFoundPage />,
    });
    setRoutes(newRoutes);
    //console.log(newRoutes);
  }, []);

  return (
    <Routes>
      {routes.map((route, i) => {
        return (
          <Route path={route.path} element={route.element} key={route.key} />
        );
      })}
    </Routes>
  );
}
