import React from "react";
import { motion } from "framer-motion";
import MenuItem from "src/components/Nav/MenuItem";
import StoreIcon from "@mui/icons-material/Store";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { getCurrentBusinessAccountUser,signOutUser } from "src/services/AuthService";
import { MenuItemProps } from "src/components/Nav/MenuItem";
import { Toast } from "src/utils/notifications";

const navigationVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const Navigation = () => {
  const [menuItems, setMenuItems] = React.useState<MenuItemProps[]>([]);

  React.useEffect(() => {
    //user = await CognitoUtils.getCurrentUser();
    const user = getCurrentBusinessAccountUser();
    const menuItemsArray = [
      {
        icon: ShoppingBasketIcon,
        text: "Home",
        onClick: () => window.location.href = process.env.REACT_APP_DOMAIN+""
      } as MenuItemProps,
      {
        icon: StoreIcon,
        text: "Your Store",
        onClick: () => window.location.href = process.env.REACT_APP_DOMAIN + "/stores/" + user?.getUsername()
      } as MenuItemProps,
    ];
    if (user) {
      menuItemsArray.push({
        icon: ShoppingBasketIcon,
        text: "Logout",
        onClick: () => {
          signOutUser(user);
          Toast("You have signed out.", "", "success");
          window.location.href = process.env.REACT_APP_DOMAIN + "/signin";
        }
      } as MenuItemProps);
    } else {
      menuItemsArray.push({
        icon: ShoppingBasketIcon,
        text: "Signin",
        onClick: () => window.location.href = process.env.REACT_APP_DOMAIN + "/signin"
      } as MenuItemProps);
    }
    setMenuItems(menuItemsArray);
  }, []);

  return (
    <motion.ul variants={navigationVariants} className="p-5 absolute top-30px">
      {menuItems && menuItems.map((item, i) => (
        <MenuItem key={i} item={item}/>
      ))}
    </motion.ul>
  );
};

export default Navigation;
