
import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import axios from "axios";
import { getPresignedUrl } from "src/services/ProductImageService";
import { FilePondFile } from "filepond";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

/**
 * FormikEditFilesField is a custom input primitive built with using useField in formik.
 *
 * Reference: https://formik.org/docs/api/useField
 *
 * @param param0
 * @returns
 */
const FormEditFilesField = ({storeIdentifier, label, fileLinks, register, setValue, getValues, ...props }: any) => {

  const [files, setFiles] = React.useState<[]>();

  React.useEffect(() => {
    if (fileLinks) {
    setFiles(
      fileLinks.map((link: any) => ({
        source: link,
        options: { type: "local" },
      }))
    );
    }
  }, [fileLinks]);

  const onInitialLoad = (
    source: any,
    load: any,
    error: any,
    progress: any,
    abort: any,
    headers: any
  ) => {
    var myRequest = new Request(source);
    fetch(myRequest, {
      method: "GET",
      mode: "cors",
      cache: "no-store",
    }).then(function (response) {
      response.blob().then(function (myBlob) {
        load(myBlob);
      });
    });
  };

  const onFileRemove =  (source: any, load:any, error:any) => {
    const newImageUrls: string[] =  getValues("productImageUrls").filter((url:string) => url !== source);
    setValue("productImageUrls", newImageUrls);  
    // call the load method before ending the function
    load();
  };

  const onReorderFiles = (files: FilePondFile[]) => {
    console.log("filePondOnReorderFiles");
    console.log(files[0]);
    console.log(files[0].origin);
    console.log(files[0].getMetadata());
  }

  const onUploadFiles = (
    fieldName:any,
    file:any,
    metadata:any,
    load:any,
    error:any,
    progress:any,
    abort:any,
    transfer:any,
    options:any
  ) => {

    // https://pqina.nl/filepond/docs/api/server/#advanced
    getPresignedUrl(storeIdentifier, file.name, 1).then(
      (presignedUrl: string) => {
        // Generate the image URL from assets domain
        let imageUrl = presignedUrl.substring(0, presignedUrl.indexOf("?"));
        let position = imageUrl.search("/images");
        imageUrl =
          process.env.REACT_APP_ASSETS_DOMAIN + imageUrl.substring(position);
       // field.value.push(imageUrl);
        getValues("productImageUrls").push(imageUrl);

        // related to aborting the request
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        // the request itself
        axios({
          method: "put",
          url: presignedUrl,
          data: file,
          headers: {
            "Content-Type": file.type,
          },
          cancelToken: source.token,
          onUploadProgress: (e) => {
            // updating progress indicator
            progress(e.lengthComputable, e.loaded, e.total);
          },
        })
          .then((response) => {
            load(file.name);
          })
          .catch((thrown) => {
            if (axios.isCancel(thrown)) {
              console.log("Request canceled", thrown.message);
            } else {
              console.log(thrown);
            }
          });

        // Setup abort interface
        return {
          abort: () => {
            source.cancel("Operation canceled by the user.");
          },
        };
      }
    );
  };

  return (
    <>
      <label htmlFor={props.id || props.name} className="form-label">
        {label}
      </label>
      <FilePond
             {...register("storeLocation")}
        {...props}
        files={files}
        allowMultiple={true}
        allowReorder={true}
        maxFiles={30}
        onreorderfiles={onReorderFiles}
        server={{
          load: onInitialLoad, // Load files
          remove: onFileRemove,
          revert: (uniqueFileId, load, error) => {
            console.log("revert called");
          },

          process: onUploadFiles,
        }}
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </>
  );
};

export default FormEditFilesField;
