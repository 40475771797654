// MyGoogleMaps.js
import React from "react";

import GoogleMapReact from "google-map-react";
import googleMapLoading from 'src/assets/images/googleMapLoading.gif';

const GoogleMapCenterMarker = ({
  storeAddress,
  lat,
  lng,
}: {
  storeAddress: string;
  lat: number;
  lng: number;
}) => {
  return (
    <>
      <div
        style={{
          border: "1px solid white",
          borderRadius: "50%",
          height: 15,
          width: 15,
          backgroundColor: "red",
          cursor: "pointer",
          zIndex: 10,
        }}
      />
      <div className="relative w-56 text-sm bg-white p-2 z-50 shadow-2xl">
        <div className="text-red-600">{storeAddress} </div>
      </div>
    </>
  );
};

export interface StoresGoogleMapProps {
  googleMapCenter: GoogleMapReact.Coords;
  storeAddress: string;
  storeLocationLatitude: number;
  storeLocationLongitude: number;
}

const StoresGoogleMapLocation = (props: StoresGoogleMapProps) => {

  const apiHasLoaded = (map: any, maps: any) => {
    //console.log(map);
    //console.log("apiHasLoaded");
  };

  const onStoreMarkerClick = () => {
   //console.log("onStoreMarkerClick");
   // console.log(props.storeLocationLatitude);
   // console.log(props.storeLocationLongitude);
  };


  return (
    <>
      {!props.googleMapCenter && <img src={googleMapLoading}/>}
      {props.googleMapCenter && (
        <div className="h-full w-full">
          <GoogleMapReact
            center={props.googleMapCenter}
            zoom={13}
            draggable={false}
            onChildClick={onStoreMarkerClick}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
              libraries: ["places", "geometry"],
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
          >
            <GoogleMapCenterMarker
              storeAddress={props.storeAddress}
              lat={props.storeLocationLatitude}
              lng={props.storeLocationLongitude}
            />
          </GoogleMapReact>
        </div>
      )}
    </>
  );
};

export default StoresGoogleMapLocation;
