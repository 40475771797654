import React, { Fragment } from "react";

import LandingPageHeader from "src/components/LandingPage/LandingPageHeader";

const ExploreButton = ({
  button_link,
  button_text,
}: {
  button_link: string;
  button_text: string;
}) => {
  return (
    <button
      className="py-2.5 px-5 m-4 text-2xl text-gray-900 bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      onClick={() => window.open(button_link, "_self")}
    >
      {button_text}
    </button>
  );
};

const HomePage = () => {
  const stores_page = process.env.REACT_APP_DOMAIN + "/stores";
  const products_page = process.env.REACT_APP_DOMAIN + "/products";

  return (
    <div>
      <div className="flex flex-col items-center justify-center h-40 font-sans font-bold  ">
        <div className="text-xl">
          Shopping is not just buying things, it is a lifestyle.
        </div>
        <div className="text-xl">
          BiMelody connects local retail stores to help you find best stores and
          products locally.
        </div>
      </div>
      <div className="mt-4">
        <img
          src="https://resources.workable.com/wp-content/uploads/2019/12/how_to_hire_in_construction.png"
          alt="img"
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  );
};

export default HomePage;
