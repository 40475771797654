import React from "react";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from "src/utils/notifications";
import { updateProduct } from "src/services/ProductService";

import { getProductInfoFromStore } from "src/services/StoreService";
import { URL_REG_MATCH } from "src/constants";
import FormEditFilesField from "src/components/Common/FormEditFilesField";

const validationSchema = Yup.object().shape({
  productName: Yup.string()
    .required("Store name can also be your brand name.")
    .min(6, "It must be at least 6 characters")
    .max(60, "It must not exceed 60 characters"),

  productDescription: Yup.string()
    .required("A good description for your business will make it prosper.")
    .min(60, "It must be at least 60 characters")
    .max(600, "It must not exceed 600 characters"),

  priceInDollar: Yup.string()
    .required("Price field is required")
    .matches(/^\d{0,8}(\.\d{1,4})?$/, "Please provide a valid price value."),

  productImageUrls: Yup.array()
    .of(Yup.string().matches(URL_REG_MATCH, "Not a valid website url!"))
    .required("Product photo is required"),

  acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
});

export type ProductAttributes = {
  productName: string;
  productDescription: string;
  priceInDollar: number;
  productImageUrls: string[];
  acceptTerms: boolean;
};

export type ProductEditFormProps = {
  storeIdentifier: string;
};


const ProductEditForm = (props: ProductEditFormProps) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const pathParameters: any = useParams();
  let navigate = useNavigate();

   React.useEffect(() => {
    getProductInfoFromStore(
      props.storeIdentifier,
      pathParameters.productIdentifier
    )
      .then((response) => {
        reset({
          productName: response?.productName || "",
          productDescription: response?.productDescription || "",
          priceInDollar: response?.priceInDollar || 0,
          productImageUrls: response?.productImageUrls || [],
          acceptTerms: false,
        });
      })
      .catch((error) =>
        Toast(
          "Product Not Found!",
          "Failed to get product information!",
          "danger"
        )
      );
  }, []);

  const onSubmit = (data: any) => {

    data.uniqueProductNameInStore = pathParameters.productIdentifier;
    const postBody = JSON.stringify(data, null, 2);
    
    updateProduct(props.storeIdentifier, pathParameters.productIdentifier, postBody)
      .then((response) => {
        if (response.ok) {
          Toast(
            "Updated!",
            "The product information has been updated. Redirect to the new product page...",
            "success"
          );
          response.json().then((responseData) => {
            navigate(
              "/stores/" +
                responseData.uniqueStoreName +
                "/products/" +
                responseData.uniqueProductNameInStore
            );
          });
          //history.push("/stores/" + data.uniqueStoreName);
        } else {
          Toast(
            "Failed to create product!",
            "Server is not able to create new product at this moment. Please try later.",
            "danger"
          );
        }
      })
      .catch((error) => {
        Toast("Updated failed!!", error.message, "danger");
      });
  };

  return (
    <div className="flex justify-center items-center">
      <form onSubmit={handleSubmit(onSubmit)} className="form w-2/3">
        <div>
          <label className="form-label">Product Name</label>
          <input
            {...register("productName")}
            type="text"
            className="form-control form-textInput"
          />
          {errors.productName && (
            <p className="error">{errors.productName.message?.toString()}</p>
          )}
        </div>

        <div>
          <label className="form-label">Product Description</label>
          <textarea
            rows={4}
            {...register("productDescription")}
            className="form-control form-textInput"
          />
          {errors.productDescription && (
            <p className="error">
              {errors.productDescription.message?.toString()}
            </p>
          )}
        </div>

        <div>
          <label className="form-label">Product Price</label>
          <input
            {...register("priceInDollar")}
            type="number"
            step="0.1"
            className="form-control form-textInput"
          />
          {errors.priceInDollar && (
            <p className="error">{errors.priceInDollar.message?.toString()}</p>
          )}
        </div>


        <div className="form-group">
          <FormEditFilesField
            storeIdentifier={props.storeIdentifier}
            name="productImageUrls"
            register={register}
            getValues={getValues}
            setValue={setValue}
            fileLinks={getValues("productImageUrls")}
          />
          {errors.productImageUrls && (
            <p className="error">{errors.productImageUrls.message?.toString()}</p>
          )}
        </div>

        <div>
          <input
            {...register("acceptTerms")}
            type="checkbox"
            className="m-1"
          />
          <label className="inline">I have verified that the product information is correct.</label>
          {errors.acceptTerms && (
            <p className="error">{errors.acceptTerms.message?.toString()}</p>
          )}
        </div>

        <div className="form-group">
          <button type="submit" className="form-button">
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProductEditForm;
